import React, { useCallback, useEffect } from 'react';
import { MainContainer, MainContent, HeaderChat, Profile } from './styles';
import Conversations from '../conversations';
import { useConversation } from '../../hooks/conversation';
import { useMain } from '../../hooks/main';
import Groups from '../groups';
import { getServerSentEventPath } from '../../storage';
import { REACT_APP_ENABLE_SERVERSENTEVENT } from '../../config';
const Main = () => {
  const { GetUserConversations, setHasCall, setSSEConnectionActive } =
    useConversation();
  const { page, setPage } = useMain();
  const handleChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    [setPage]
  );

  // if identification id not exist => return to login page
  useEffect(() => {
    console.log(localStorage.getItem('@Squre:IdentificationId'));
    if (!localStorage.getItem('@Squre:IdentificationId')) {
      window.location.href = '/';
    } else {
      //console.log(REACT_APP_ENABLE_SERVERSENTEVENT);
      if (REACT_APP_ENABLE_SERVERSENTEVENT === 'true') {
        // Retrieve the receiver ID from localStorage
        const receiverId = localStorage.getItem('@Squre:IdentificationId');

        // Open SSE connection
        const eventSource = new EventSource(
          `${getServerSentEventPath()}?receiverId=${receiverId}`
        );

        eventSource.onopen = function () {
          //console.log('Connection established');
          setSSEConnectionActive(true);
        };

        eventSource.onmessage = async function (event) {
          //console.log('New SSE Event: ', event.data);
          try {
            await GetUserConversations();
            if (
              event.data.toString() == 'Call All' ||
              event.data.toString() == 'Call to only this User'
            ) {
              setHasCall((prevHasCall) => (prevHasCall === 0 ? 1 : 0));
            }
          } catch (error) {
            console.error('Error in EventSource message event:', error);
          }
        };

        eventSource.onerror = function (event) {
          setSSEConnectionActive(false);
          console.error('Error in EventSource:', event);
          console.error('Error target:', event.target);
          console.error('EventSource readyState:', event.target.readyState);

          if (event.target.readyState === EventSource.CONNECTING) {
            console.error(
              'The connection is in the process of being established.'
            );
          } else if (event.target.readyState === EventSource.OPEN) {
            console.error('The connection was unexpectedly closed.');
          } else if (event.target.readyState === EventSource.CLOSED) {
            console.error('The connection has been closed.');
          }
        };

        // Cleanup the EventSource connection when component unmounts
        return () => {
          eventSource.close();
          setSSEConnectionActive(false);
          //console.log('SSE connection closed');
        };
      }
    }
  }, []);

  return (
    <MainContainer>
      <MainContent>
        <Conversations />
      </MainContent>
    </MainContainer>
  );
};

export default Main;
