/* eslint-disable prettier/prettier */
import SendIcon from '@material-ui/icons/Send';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Picker from 'emoji-picker-react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import {
  FaFilePdf,
  FaFileAlt,
  FaFile,
  FaFileVideo,
  FaChevronDown,
  FaArrowCircleUp,
  FaAngleUp,
  FaFileAudio,
} from 'react-icons/fa';
import {
  CreateAttachment,
  ReplyMessage,
  SendMessageUsingContactInformation,
  SendMessageUsingFilter,
  UploadToAws,
} from '../../services/chat';
import Linkify from 'react-linkify';
import {
  getUserGuid,
  getPortalServerIp,
  getIdentificationId,
} from '../../storage';
import {
  autoUrl,
  convertBackendDate,
  getStringDate,
  streamFile,
} from '../../utils';
import loadingIcon from '../../assets/images/loading.gif';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import { TiArrowForward } from 'react-icons/ti';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import './styles.css';
import {
  Content,
  Container,
  SendContent,
  InputContainer,
  MessageContent,
  MessageMarked,
  ButtonClose,
  RepliedMessage,
  ForwardText,
  BottomContent,
  Input,
  AttachmentContainer,
  AttachmentFile,
  Unread,
  ButtonScrollDown,
  DropArea,
  FileDetailsContainer,
  FileName,
  FileTypeSize,
} from './styles';
import { useLoading } from '../../hooks/loading';
import ActionsMessage from '../../components/actionsmessage';
import { useChatMessages } from '../../hooks/chat';
import { GetMessagesForConversations } from '../../services/conversations';
import Forward from '../../components/Forward';
import { IconButton, LinearProgress } from '@material-ui/core';
import DialogImage from './DialogImage';
import CreatePollDialog from './CreatePollDialog';
import { useToast } from '../../hooks/toast';
import { AccessTime, CenterFocusStrong } from '@material-ui/icons';
import { BalloonComponent } from '../../components/balloon';
import { useIntl } from 'react-intl';
import {useDropzone} from 'react-dropzone';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { REACT_APP_ENABLE_SERVERSENTEVENT } from '../../config';
const Chat = ({ conversationId }) => {
  const intl = useIntl();

  const { startLoading, loading, stopLoading } = useLoading();
  const { addToast } = useToast(false);
  const [openEmoji, setOpenEmoji] = useState(false);
  // prettier-ignore
  const {
    messages,
    hasUpdate,
    unreadMessages,
    addMessageManually,
    setHasUpdate,//added for SSE update purpose
  } = useChatMessages();
  const [files, setFiles] = useState([]);
  const [pastMessages, setPastMessages] = useState([]);
  const [base64File, setBase64File] = useState();
  const [lastUpdate, setLastUpdate] = React.useState(-1);
  const [selectedMessage, setSelectedMessage] = React.useState();
  const [attSelected, setAttSeleted] = React.useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedMessageForward, setSelectedMessageForward] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [more, setMore] = React.useState(false);
  // prettier-ignore
  const [moreMessagesAvailable, setMoreMessagesAvailable] = React.useState(
    true
  );
  const userGuid = getUserGuid();
  const messagesEndRef = useRef(null);
  const messagePastRef = useRef(null);
  const scroll = useRef(null);
  const inputSendText = useRef(null);
  const fileRef = useRef(null);
  const formRef = useRef(null);
  const refBottom = useRef(null);
  const [pbottom, setPBottom] = useState(0);
  const [topS, setTopS] = useState(0);
  const urlDowload = `${getPortalServerIp()}/CareServantAttachments/AttachmentManagerService.svc/DownloadAttachment/${getIdentificationId()}`;
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [openPollDialog, setOpenPollDialog] = useState(false);

  useEffect(() => {
    setPastMessages([]);
    setMoreMessagesAvailable(true);
    setTopS(0);
    inputSendText.current.focus();
  }, [conversationId]);

  const submit = useCallback(
    async (event) => {
      if (event) event.preventDefault();
      try {
        if (!inputSendText.current.value && files.length === 0) {
          addToast({
            title: intl.formatMessage({ id: 'MessageCannotBeEmpty' }),
            type: 'error',
          });
          return;
        }
        startLoading();
        const Attachments = [];
        if (files) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const result = await CreateAttachment({
              attachmentName: file.name,
              attachmentType: file.type,
            });

            if (result.CreateAttachmentResult) {
              Attachments.push(result.attachment);
              try {
                const response = await UploadToAws({
                  file,
                  attachmentGuid: result.attachment.Guid,
                });
              } catch (error) {
                addToast({
                  title: intl.formatMessage({ id: 'ErrorUploadingFile' }),
                  type: 'error',
                });
              }
            } else {
              addToast({
                title: intl.formatMessage({ id: 'MessageWasNotSent' }),
                type: 'error',
              });
            }
          }
        }
        if (selectedMessage) {
          console.log(selectedMessage)
          if (selectedMessage.action === 'reply') {
            const result = await ReplyMessage({
              messageGuid: selectedMessage.message.Guid,
              reply: inputSendText.current.value,
            });
            if (result.repliedMessage) {
              setSelectedMessage();
            }
          }
        } else {
          if (!conversationId.isGroup) {
            await SendMessageUsingContactInformation({
              contactInformation: conversationId.Owner,
              Message: inputSendText.current.value,
              conversationGuid: conversationId.Guid,
              Attachments,
              IsPoll:false,
            });
          } else {
            const response = await SendMessageUsingFilter({
              conversationGuid: conversationId.Owner.StaffGuid,
              Message: inputSendText.current.value,
              Attachments,
              IsPoll:false,
            });
          }
        }
        inputSendText.current.value = '';
        setOpenEmoji(false);
        if(REACT_APP_ENABLE_SERVERSENTEVENT === 'true') {
        setHasUpdate(hasUpdate == 0 ? 1 : 0);
        }
        setLastUpdate(hasUpdate);
        fileRef.current.value = null;
        setFiles([]); 
        stopLoading();
      } catch (error) {
        stopLoading();
        console.log(error);
        addToast({
          title: intl.formatMessage({ id: 'MessageWasNotSent' }),
          type: 'error',
        });
      }
    },
    [
      conversationId.Guid,
      conversationId.Owner,
      conversationId.isGroup,
      files,
      hasUpdate,
      selectedMessage,
      startLoading,
      stopLoading,
    ]
  );
  const onEmojiClick = (event, emojiObject) => {
    inputSendText.current.focus();
    event.preventDefault();
    inputSendText.current.value += '' + emojiObject.emoji;
  };
  const onClinkOpenEmojis = useCallback(() => {
    setOpenEmoji(!openEmoji);
  }, [openEmoji]);
  const scrollToBottom = useCallback(() => {
    setPastMessages([]);
    setLoadingMessages(false);
    setMoreMessagesAvailable(true);
    GetMessagesForConversations({
      Guid: conversationId.Guid,
      maxNumberMessages: 40,
    }).then((data) => {
      if (data.GetMessagesForConversationResult) {
        messagesEndRef.current.scrollIntoView();
      }
    });
  }, [conversationId.Guid]);

  const scrollSimple = useCallback(() => {
    setPastMessages([]);
    setMoreMessagesAvailable(true);
    setLoadingMessages(false);
    messagesEndRef.current.scrollIntoView();
  }, [messagesEndRef]);

  useEffect(scrollToBottom, [scrollToBottom, hasUpdate, conversationId.Guid]);

  const scrollTo = (event) => {
    setTopS(event.target?.scrollTop);
  };

  const scrollToMessage = (id) => {
    try {
      const div = document.getElementById(id);
      div.scrollIntoView();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (topS < 100) {
      setMore(true);
    }
  }, [topS]);
  useEffect(() => {
    if (messagePastRef && messagePastRef.current) {
      messagePastRef.current.scrollIntoView();
    }
  }, [pastMessages]);
  useEffect(() => {
    const getMoreMessages = async () => {
      const message = pastMessages.length === 0 ? messages[0] : pastMessages[0];
      if (message && moreMessagesAvailable) {
        setLoadingMessages(true);
        const response = await GetMessagesForConversations({
          Guid: message.ConversationGuid,
          indexMessageGuid: message.Guid,
          maxNumberMessages: -20,
        });
        let list = pastMessages.filter((message) => !!message.Guid);
        if (response.GetMessagesForConversationResult) {
          setMoreMessagesAvailable(response.moreMessagesAvailable);
          let listResponse = response.orderedMessages;
          listResponse.push(
            <div
              ref={messagePastRef}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 5,
                paddingRight: 5,
                color: '#999',
              }}
            />
          );
          for (let i = 0; i < list.length; i++) {
            const item = list[i];
            listResponse.push(item);
          }
          setPastMessages(listResponse);
          setLoadingMessages(false);
        }
      }
    };
    if (more) {
      getMoreMessages();
    }
    setMore(false);
  }, [more]);

  useEffect(() => {
    console.log('base64File');
    console.log(base64File);
  }, [base64File]);

  const addPollMessage = useCallback(async (message) => {
    if (!conversationId.isGroup) {
      await SendMessageUsingContactInformation({
        contactInformation: conversationId.Owner,
        Message: message,
        conversationGuid: conversationId.Guid,
        IsPoll:true,
      });
    } else {
      const response = await SendMessageUsingFilter({
        conversationGuid: conversationId.Owner.StaffGuid,
        Message: message,
        IsPoll:true,
      });
    }
    setOpenPollDialog(false);
  }, [conversationId]);

  const markedMessage = () => {
    return (
      <MessageContent>
        <MessageMarked>
          <AttachmentContainer>
            {selectedMessage.message?.Attachments.map((att, key) => {
              return (
                <AttachmentFile key={key}>
                  {att &&
                    (att.AttachmentType === 0 || att.AttachmentType === 1) && (
                      <img
                        alt={att.Name}
                        src={`${urlDowload}/${att.Guid}`}
                        onClick={() => setAttSeleted(att)}
                      />
                    )}
                  {att && att.AttachmentType === 20 && (
                    <video controls height="250" width="250">
                      <source src={`${urlDowload}/${att.Guid}`} />
                    </video>
                  )}

                  {att && att.AttachmentType === 10 && (
                    <a href={`${urlDowload}/${att.Guid}`} download>
                      <FaFilePdf size={30} color="red" />
                    </a>
                  )}
                  {att && att.AttachmentType === 11 && (
                    <a href={`${urlDowload}/${att.Guid}`} download>
                      <FaFileAlt size={30} color="#666" />
                    </a>
                  )}
                  {att && att.AttachmentType === 100 && (
                    <a href={`${urlDowload}/${att.Guid}`} download>
                      <FaFile size={30} color="#333" />
                    </a>
                  )}
                </AttachmentFile>
              );
            })}
          </AttachmentContainer>
          {selectedMessage.message?.Message}
          <ButtonClose onClick={() => setSelectedMessage()}>
            <HighlightOffOutlinedIcon />
          </ButtonClose>
        </MessageMarked>
      </MessageContent>
    );
  };

  const handleAttachChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if(selectedFiles.length > 0) {
      const existingFileNames = new Set(files.map(file => file.name + file.size));
  
      const newFiles = selectedFiles.filter(file => !existingFileNames.has(file.name + file.size));
    
      if (newFiles.length === 0) {
        addToast({
          title: intl.formatMessage({ id: 'SelectedFilesAreAlreadyAttached' }),
          type: 'info',
        });
        return;
      }
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const removeFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    fileRef.current.value = null;
  };

  const getImage = () => {
    const imageFiles = files.filter(file => file.type.includes("image"));
    const otherFiles = files.filter(file => !file.type.includes("image"));
  
    const renderFilePreview = (file) => {
      let icon = null;
      let color = "#333";
      let fileType = '';
  
      if (file.type === "application/pdf") {
        icon = <FaFilePdf size={40} color="red" />;
        fileType = "PDF";
      } else if (file.type === "plain/txt") {
        icon = <FaFileAlt size={40} color="#666" />;
        fileType = "Text";
      } else if (file.type.includes("video")) {
        icon = <FaFileVideo size={40} color="#666" />;
        fileType = "Video";
      } else if (file.type.includes("audio")) {
        icon = <FaFileAudio size={40} color="#f777a0" />;
        fileType = "Audio";
      } else {
        icon = <FaFile size={40} color="#333" />;
        fileType = "File";
      }
  
      return (
        <div key={file.name} style={{ width: '150px' }}>
          <MessageContent>
            <MessageMarked style={{ height: 130, width: 130, paddingRight:5 }} >
              {icon}
              <ButtonClose onClick={() => removeFile(file)}>
                <HighlightOffOutlinedIcon />
              </ButtonClose>
              <FileDetailsContainer>
                <FileName title={file.name}>{file.name}</FileName>
                <FileTypeSize>
                  {fileType} <span style={{ padding: '0px 2px' }}>|</span>{' '}
                  {(file.size / (1024 * 1024)).toFixed(2)} MB
                </FileTypeSize>
              </FileDetailsContainer>
            </MessageMarked>
          </MessageContent>
        </div>
      );
    };
  
    const renderImagePreview = (file) => {
      return (
        <div key={file.name} style={{ marginRight: '10px'}}>
          <MessageContent>
            <MessageMarked style={{ height: 130, width: 130 }} >
              <img src={URL.createObjectURL(file)} alt="" style={{ height: 100, width: 100 }} />
              <ButtonClose onClick={() => removeFile(file)}>
                <HighlightOffOutlinedIcon />
              </ButtonClose>
            </MessageMarked>
          </MessageContent>
        </div>
      );
    };
  
    return (
      <div style={{ marginLeft: '90px', maxHeight: '300px', overflowY: 'auto', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {otherFiles.map((file, index) => renderFilePreview(file, index))}
        {imageFiles.map((file, index) => renderImagePreview(file, index))}
      </div>
    );
  }; 

  useEffect(() => {
    if (selectedMessageForward) {
      setOpen(true);
    }
  }, [selectedMessageForward]);
  useEffect(() => {
    inputSendText.current.focus();
  }, [selectedMessage]);
  useEffect(() => {
    if (openEmoji) {
      scrollSimple();
    }
  }, [openEmoji, scrollSimple]);

  useEffect(() => {
    setPBottom((state) => refBottom.current?.clientHeight);
  }, [refBottom.current?.clientHeight]);
  useEffect(() => {
    scrollToBottom();
  }, [pbottom]);
  const handleKeyDown = (event) => {
    if (event.which === 13 && !event.shiftKey) {
      // console.log(refBottom.current.clientHeight);
      submit();
      event.preventDefault();
    }
    if (event.which === 13 && event.shiftKey) {
      // event.preventDefault();
    }
  };
  useEffect(() => {
    if (attSelected) {
      setOpenDialog(true);
    }
  }, [attSelected]);

  const dispatchChangeEvent = () => {
    if (fileRef.current) {
      const event = new Event('change', { bubbles: true });
      fileRef.current.dispatchEvent(event);
    }
  };
  
  const onDrop = useCallback(async (acceptedFiles) => {
    if (fileRef.current) {
      const dataTransfer = new DataTransfer();
      acceptedFiles.forEach(file => dataTransfer.items.add(file));
      fileRef.current.files = dataTransfer.files;
  
      dispatchChangeEvent();
    }
  }, []);

  const {
    getRootProps,
    isDragAccept,
    isDragReject,
    isDragActive
  } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <>
      { isDragActive && 
        <DropArea {...getRootProps({ isDragAccept, isDragReject, isDragActive })}>
          <CloudUploadOutlinedIcon style={{ marginRight: '10px' }} />
          {isDragActive && !isDragReject && <p>Drop files here</p>}
          {isDragReject && <p>Some files will be rejected</p>}
        </DropArea>
      }
      
      <Container
        {...getRootProps({ isDragAccept, isDragReject, isDragActive })}
      >
        <Content
          isMessageSelected={!!selectedMessage}
          isEmojisOpened={!!openEmoji}
          height={pbottom - 20}
        >
          {loadingMessages && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 5,
                right: 0,
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <LinearProgress
                color="secondary"
                variant="indeterminate"
                style={{ width: '100%' }}
              />
            </div>
          )}
          {pastMessages.map((message) => {
            if (message && message.Guid) {
              let formatedDate = new Date(
                convertBackendDate(message.MessageDateTime)
              );
              const isDeleted =
                message.Message ===
                intl.formatMessage({ id: 'ThisMessageHasBeenDeleted' });
              let text =
                message.ReferencedMessage && message.IsForward === true
                  ? message.ReferencedMessage.Message
                  : message.Message;
              if (isDeleted) {
                text = intl.formatMessage({ id: 'ThisMessageHasBeenDeleted' });
              }
              return (
                <React.Fragment key={message.Guid}>
                  {unreadMessages.length > 0 &&
                    unreadMessages[0].Guid === message.Guid && (
                      <Unread key={unreadMessages[0].Guid}>
                        {intl.formatMessage({ id: 'UnreadMessages' })};
                      </Unread>
                    )}
                  <BalloonComponent
                    scrollTo={scrollToMessage}
                    message={message}
                    text={text}
                    conversationId={conversationId}
                    isDeleted={isDeleted}
                    userGuid={userGuid}
                    formatedDate={formatedDate}
                    urlDowload={urlDowload}
                    setAttSeleted={setAttSeleted}
                    setSelectedMessage={setSelectedMessage}
                    setSelectedMessageForward={setSelectedMessageForward}
                    setOpen={setOpen}
                  />
                </React.Fragment>
              );
            } else {
              return message;
            }
          })}
          {messages.map((message) => {
            let formatedDate = '';
            const isDeleted =
              message.Message ===
              intl.formatMessage({ id: 'ThisMessageHasBeenDeleted' });
            formatedDate = new Date(
              convertBackendDate(message.MessageDateTime)
            );

            let text =
              message.ReferencedMessage && message.IsForward === true
                ? message.ReferencedMessage.Message
                : message.Message;
            if (isDeleted) {
              text = intl.formatMessage({ id: 'ThisMessageHasBeenDeleted' });
            }
            return (
              <React.Fragment key={message.Guid}>
                {unreadMessages.length > 0 &&
                  unreadMessages[0].Guid === message.Guid && (
                    <Unread key={unreadMessages[0].Guid}>
                      {intl.formatMessage({ id: 'UnreadMessages' })};
                    </Unread>
                  )}
                <BalloonComponent
                  scrollTo={scrollToMessage}
                  message={message}
                  text={text}
                  conversationId={conversationId}
                  isDeleted={isDeleted}
                  userGuid={userGuid}
                  formatedDate={formatedDate}
                  urlDowload={urlDowload}
                  setAttSeleted={setAttSeleted}
                  setSelectedMessage={setSelectedMessage}
                  setSelectedMessageForward={setSelectedMessageForward}
                  setOpen={setOpen}
                />
              </React.Fragment>
            );
          })}
          <div ref={messagesEndRef} />
        </Content>
      </Container>
      {scroll !== null &&
        scroll.current?.scrollHeight - scroll?.current?.scrollTop !==
          scroll.current?.clientHeight && (
          <ButtonScrollDown {...getRootProps({ isDragActive })}>
            <IconButton size="small" color="inherit" onClick={scrollSimple}>
              <FaChevronDown size={16} />
            </IconButton>
          </ButtonScrollDown>
        )}
      <BottomContent ref={refBottom}>
        {selectedMessage && markedMessage()}
        {files.length > 0 && getImage()}
        {openEmoji && <Picker onEmojiClick={onEmojiClick} />}
        <SendContent ref={formRef} onSubmit={submit}>
          <IconButton
                title={intl.formatMessage({
                  id: 'CreatePoll',
                })}
                onClick={() => setOpenPollDialog(true)}
              >
                <PollOutlinedIcon />
          </IconButton>
          <label htmlFor="attach">
            <AttachFileIcon />
            <input
              ref={fileRef}
              multiple
              type="file"
              id="attach"
              onChange={handleAttachChange}
            />
          </label>
          <IconButton onClick={onClinkOpenEmojis}>
            <InsertEmoticonIcon />
          </IconButton>
          <InputContainer>
            <Input
              autoFocus={true}
              spellCheck={false}
              multiline
              inputRef={inputSendText}
              placeholder={intl.formatMessage({ id: 'TypeMessage' })}
              onKeyDown={handleKeyDown}
            />
            {loading && <img src={loadingIcon} alt="loading" />}
          </InputContainer>
          <button type="submit">
            <SendIcon />
          </button>
        </SendContent>
      </BottomContent>
      <Forward
        open={open}
        message={selectedMessageForward}
        handleClose={() => {
          setOpen(false);
          setSelectedMessageForward();
        }}
      />
      <DialogImage
        att={attSelected}
        open={openDialog}
        close={() => setOpenDialog(false)}
      />
      <CreatePollDialog
        handleCancel={() => setOpenPollDialog(false)}
        handleOk={addPollMessage}
        open={openPollDialog}
      />
    </>
  );
};
export default Chat;
