/* eslint-disable no-undef */
export const REACT_APP_POOL_TIME = process.env.REACT_APP_POOL_TIME;
export const REACT_APP_POOL_TIME_2 = process.env.REACT_APP_POOL_TIME_2;
export const REACT_APP_URL = process.env.REACT_APP_URL;
export const VERSION = process.env.REACT_APP_VERSION;
export const REACT_APP_RESET_PASSWORD_URL =
  process.env.REACT_APP_RESET_PASSWORD_URL;
export const REACT_APP_ENABLE_MAKECALL = process.env.REACT_APP_ENABLE_MAKECALL;
export const REACT_APP_ENABLE_SERVERSENTEVENT =
  process.env.REACT_APP_ENABLE_SERVERSENTEVENT;
export const REACT_APP_VIEW_SSECONNECTIONSTATE =
  process.env.REACT_APP_VIEW_SSECONNECTIONSTATE;
